<template>
  <div class="bg-black">
    <!-- Your HTML code goes here -->
 
     <h1 className="text-3xl font-bold underline">
      Hello world!
    </h1>

    <h1 className="text-3xl font-bold underline">
      Hello world!
    </h1>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  // Your JavaScript code goes here
}
</script>

<style scoped>
/* Your CSS code goes here */
</style>