import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen bg-gray-100 flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TodoList = _resolveComponent("TodoList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TodoList)
  ]))
}